#chooseslot1 {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 0.5rem;
  margin-top: 5rem;
  border: 1px solid rgb(211, 211, 211);
  background-color: white;
}
#chooseslot2 {
  font-size: 1.2rem;
  font-weight: 600;
}
#chooseslot3 {
  font-weight: 500;
  margin-top: 0.3rem;
}
#chooseslot4 {
  box-shadow: none;
  width: 90%;
  border: 1px solid rgb(133, 133, 133);
}
#chooseslot5 {
  border: 1px solid blue;
  background-color: white;
  color: blue;
  border-radius: 2rem;
}
#chooseslot6 {
  font-weight: 500;
  margin-top: 0.6rem;
}
#chooseslot7 {
  box-shadow: none;
  width: 90%;
  border: 1px solid rgb(133, 133, 133);
  margin-top: 0.8rem;
}
#chooseslot8 {
  font-size: 0.9rem;
  color: rgb(133, 133, 133);
  margin-top: 1.5rem;
}
#chooseslot9 {
  color: blue;
  font-weight: 600;
  cursor: pointer;
}
#chooseslot10 {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 1.2rem;
  width: 30%;
}
#chooseslot11{
  color: blue;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  /* margin-top: 0.6rem; */
}


@media (max-width: 576px) {
    
    #chooseslot7 {
        box-shadow: none;
        width: 100%;
        border: 1px solid rgb(133, 133, 133);
        margin-top: 0.8rem;
      }

      #chooseslot10 {
        margin-top: 1rem;
        padding: 0.5rem;
        border-radius: 1.2rem;
        width: 100%;
      }
      #chooseslot4 {
        box-shadow: none;
        width: 100%;
        border: 1px solid rgb(133, 133, 133);
      }
      #chooseslot2 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-top: 1rem;
      }
  }
  