#material1 {
  border-radius: 16px;
  background: linear-gradient(88deg, #4623b2 0.49%, #1726ae 99.81%);
  padding: 1rem;
}
#material2 {
  margin-top: 1rem;
}
#material3 {
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.4rem;
}
.search-contain {
  position: relative;
  margin-top: 0.4rem;
}
.search-ico {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 24px;
  stroke-width: 1.5px;
  stroke: #858ea7;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
}
#material4 {
  /* margin-top: 1rem; */
  border-radius: 16px;
  background: var(--Base-White, #fcfcfc);
  color: #8892a3;
  border: none;
  box-shadow: none;
  padding-right: 40px;
  font-size: 1.1rem;
}
#material4::placeholder {
  color: #8892a3;
  font-size: 1.1rem;
}