/* Loader container to center it on the screen */
.loader1 {
  background: transparent !important;
  border: none;
  color: white;
}

.loader1 .modal-content {
  background: transparent !important;
  border: none !important;
  display: flex;
  align-items: center;
  animation: 2s linear infinite;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  z-index: 9999;
}

.spinner {
  position: relative;
  width: 150px;
  height: 150px;
}

/* Spinning border */
.spinner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #193b94;
  border-radius: 50%;
  animation: spin 4s linear infinite;
  background-color: white;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  height: auto;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
