#construction1 {
  padding: 1rem;
  border-radius: 16px;
  background: linear-gradient(88deg, #4722af 0.49%, #1327df 99.81%);
}
#construction2 {
  margin-top: 1rem;
}
#construction3 {
  text-align: center;
  color: white;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.3rem;
  font-family: "DM Sans", sans-serif;
}
#construction4 {
  margin-top: 1rem;
  border-radius: 16px;
  background: #18191b;
  color: #8892a3;
  border: none;
  box-shadow: none;
  padding-right: 40px;
  font-size: 1.1rem;
  font-family: "DM Sans", sans-serif;
}
#construction4::placeholder {
  color: #8892a3;
  font-size: 1.1rem;
  font-family: "DM Sans", sans-serif;
}
#construction5 {
  margin-top: 0.5rem;
}
#construction6 {
  color: white;
}
.search-container {
  position: relative;
}
.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 24px;
  stroke-width: 1.5px;
  stroke: #f8f8f8;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
}
#construction7 {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.9rem;
  border-radius: 100%;
}
#construction9 {
  margin-top: 0.8rem;
  margin-bottom: 0.6rem;
}
#construction9Col {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
#construction10 {
  margin-top: 0.6rem;
  color: white;
  margin-left: -0.1rem;
  font-family: "DM Sans", sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
#construction12 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
  cursor: pointer;
}
#construction12Active {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
  cursor: pointer;
  border: 2px solid #fbfbfb;
  border-radius: 5rem;
  width: 44px;
  height: 44px;
  padding: 5px;
  transition: 0.5s all ease-in-out;
}
.img-fluid {
  width: 100%;
  object-fit: cover;
  height: 200px;
  border-radius: 1.2rem;
}
.text-center {
  margin-top: 1.6rem;
}
#construction13 {
  margin-bottom: 2rem;
}
#image-container {
  position: relative;
  display: inline-block;
}

.image-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}

/* Home.css */
#image-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

#image-container img {
  width: 100%;
  height: 200px;
  display: block;
}

#image-container .heart-icon {
  position: absolute;
  top: 9px;
  right: 20px;
  background-color: #5d5d5e;
  color: white;
  border-radius: 100%;
  padding: 5px;
  z-index: 2;
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
}
#image-container .wishlisted-heart-icon {
  position: absolute;
  top: 9px;
  right: 20px;
  background-color: #fff;
  color: red;
  border-radius: 100%;
  padding: 5px;
  z-index: 2;
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
}
/* #image-container .heart-icon-loading {
  position: absolute;
  top: 9px;
  right: 20px;
  background-color: #fff;
  color: red;
  border-radius: 100%;
  padding: 5px;
  z-index: 2;
  font-size: 2rem;
  font-weight: 600;
  cursor: ;
  font-family: "DM Sans", sans-serif;
} */
#category-img {
  border-radius: 5rem;
  width: 36px;
  height: 36px;
}
#category-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#no-product-found {
  font-family: "DM Sans", sans-serif;
  text-align: center;
  font-weight: 500;
  margin-top: 6rem;
  font-size: 1.15rem;
}
