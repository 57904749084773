#card-container {
  position: relative;
  margin-top: 1.6rem;
}
#card-image {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  width: 100%;
  height: 215px;
  object-fit: cover;
}
.semitransparent-button {
  display: inline-block;
  padding: 10px 14px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  border-radius: 20rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 15px;
  right: 16px;
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
}
.semitransparent-button:hover {
  background-color: rgba(0, 0, 0, 0.6); /* Darker on hover */
}
#card-bottom-container {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 10px 15px;
  border: 1.2px solid #bdc8dc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
#card-open-title-container {
  padding: 10px 15px;
  border: 1.2px solid #bdc8dc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
#card-open-body-container {
  padding: 10px 15px;
  border-top-width: 0px;
  border: 1.2px solid #bdc8dc;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  cursor: pointer;
  border-top-width: 0px;
}
#card-number-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 0.6rem;
  margin-bottom: 1.5rem;
  font-family: "DM Sans", sans-serif;
}
#card-number-text {
  width: 90%;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
}
#card-open-title {
  color: #788396;
  font-family: "DM Sans", sans-serif;
}
#card-title {
}

.custom-progress-bar {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2rem;
  margin-bottom: 0.5rem;
}
#project-progress-text {
  color: gray;
  font-size: 13.5px;
  font-family: "DM Sans", sans-serif;
}
#onsite-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}
#onsite-status-label {
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
}

#onsite-status-div {
  background-color: #f2f2f2;
  border-radius: 1rem;
  padding: 5px 10px;
  font-family: "DM Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
#project-manager-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}
#project-manger-name {
  font-weight: 600;
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
}
#project-manger-number {
  font-weight: 500;
  font-size: 14px;
  color: gray;
  font-family: "DM Sans", sans-serif;
}
#active-div {
  width: 10px;
  height: 10px;
  background-color: #4bbc49;
  border-radius: 1rem;
}
#meeting-url {
  color: blue;
  font-weight: 400;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
}

.GoogleMeetLink {
  color: blue;
}
.LinkCardTimeDisc {
  margin: 0;
  padding: 0;
  color: #788396;
  font-weight: 500;
}
