#Addcomment1{
    color:#3C9876;
    font-size: 1.3rem;
    font-weight: 500;
}
#Addcomment2{
    margin-bottom: 1rem;
}
#Addcomment3{
    color:#747F9C;
    margin-top: 0.3rem;
    font-weight: 400;
}
#Addcomment4{
    margin-top: 0.3rem;
}
#Addcomment5{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
#Addcomment6{
    width:15%;
    border: 1px solid #1757ff;
    color: #1757ff;
    font-weight: 500;
    border-radius: 0.3rem;
    padding: 0.4rem;
    margin-top: 0.5rem;
    margin-right: 1.4rem;
}
