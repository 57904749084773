#materialFilterComp1 {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
    font-family: "DM Sans", sans-serif;
  }
  
  #materialFilterComp2 {
    color: #777F8C;
    font-size: 1.1rem;
  }
  
  #materialFilterComp3 {
    color: #2e343e;
    font-size: 0.9rem;
  }
  