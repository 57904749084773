#materialDetailsBreadcrumbMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#materialDetailsBreadcrumb {
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 23.87px;
  text-align: left;
  color: #1757ff;
}
#materialDetailsBreadcrumbSpan {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
#materialDetailsBreadcrumbSpan:hover {
  font-weight: 600;
}

#materialdetails1 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
#materialdetails2 {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
#materialdetails3 {
  margin-left: 1rem;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  color: #3b414a;
  font-weight: 400;
  margin-bottom: 1rem;
}
#materialdetails4 {
  margin-top: 0.7rem;
}
#materialdetails5 {
  margin-top: 0.8rem;
  color: #747f9c;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
#materialdetails6 {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #2e343e;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
#materialdetails7 {
  margin-top: 2rem;
}
#materialdetails8 {
  margin-top: 1rem;
  color: #1757ff;
  font-size: 0.9rem;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
#materialdetails9 {
  margin-top: 1.3rem;
}
#materialdetails10 {
  margin-top: 0.9rem;
}
#materialdetails10Radio {
  color: #788396;
  font-size: 0.9rem !important;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
#materialdetails11 {
  font-family: "DM Sans", sans-serif;
  color: #788396;
  font-weight: 500;
  font-size: 0.8rem;
}
#materialdetails12 {
  width: 95%;
}
#materialdetails13 {
  margin-top: 0.5rem;
}
#materialdetails14 {
  width: 100%;
  border: 1px solid #1757ff;
  border-radius: 0.3rem;
  padding: 0.6rem;
  font-family: "DM Sans", sans-serif;
  color: #1757ff;
  font-weight: 500;
  font-size: 0.8rem;
}
#materialdetails15 {
  width: 100%;
  background-color: #1757ff;
  border-radius: 0.3rem;
  color: white;
  padding: 0.6rem;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 0.8rem;
}
#materialdetails16 {
  margin-top: 1rem;
}
#materialdetails17 {
  margin-top: 5rem;
  margin-bottom: 1rem;
}
#materialdetails18 {
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  margin-left: 1rem;
}
