#header1 {
  padding: 10px 0;
  /* margin-top: 1.3rem; */
  /* position: fixed;
  top: 0px; */
  /* height: 100px;    */
  /* width: 100%; */
  /* background:  linear-gradient(#e3eaff, #f7f8fc, #fff); */
  background-color: #fff;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.nav-item {
  font-family: "DM Sans", sans-serif;
  font-size: small;
  font-style: normal;
  font-weight: 400;
}

.nav-item a {
  font-family: "DM Sans", sans-serif;
  font-size: medium;
  font-style: normal;
  font-weight: 400;
  color: #7b8aa4;
  text-decoration: none;
}

.nav-item a.active {
  font-family: "DM Sans", sans-serif;
  font-size: medium;
  font-style: normal;
  font-weight: 400;
  color: #363c46;
  font-weight: 600;
}

#header2 {
  width: 100%;
  display: flex;
  align-items: start;
  margin-top: 0.3rem;
  cursor: pointer;
}

#header3 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 0.2rem;
}

#logo {
  width: 8rem;
  /* height: 100%; */
}

/* offcanvas header */
#mobileViewHeader {
  display: none;
}

/* .navbar {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.offcanvasNavbarList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* display: flex; */
  gap: 20px;
}

.offcanvasNavbarItem {
  font-family: "DM Sans", sans-serif;
  font-size: small;
  font-style: normal;
  font-weight: 400;
  margin-top: 1rem;
}

.offcanvasNavbarItem a {
  font-family: "DM Sans", sans-serif;
  font-size: medium;
  font-style: normal;
  font-weight: 400;
  color: #7b8aa4;
  text-decoration: none;
}

.offcanvasNavbarItem a.active {
  font-family: "DM Sans", sans-serif;
  font-size: medium;
  font-style: normal;
  font-weight: 400;
  color: #363c46;
  font-weight: 600;
}

#offcanvasNavbarHeader2 {
  margin-top: 1.5rem;
}

#offcanvasNavbarHeader3 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 0.2rem;
  background-color: #fff;
}

#loginButton {
  background-color: transparent;
  border: 1px solid #c2c2c2;
  color: #1757ff;
  font-family: "DM Sans", sans-serif;
  border-radius: 0.8rem;
  width: 90%;
}
#loginButton:hover {
  transition: 0.5s ease-in-out;
  background-color: #1757ff;
  color: #fff;
  border: none;
}

@media (max-width: 768px) {
  #header1 {
    display: none;
  }
  #mobileViewHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.7rem;
    padding-left: 0.7rem;
    width: 100%;
  }
  #logo {
    width: 7rem;
    height: 100%;
  }
  #header2 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    /* margin-top: -5.5rem; */
    display: none;
  }
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.2rem;
    display: none;
  }

  .nav-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
  }

  .nav-item {
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }

  .nav-item a {
    font-family: "DM Sans", sans-serif;
    color: #7b8aa4;
    text-decoration: none;
  }

  .nav-item a.active {
    color: #363c46;
    font-weight: 400;
  }
  #navbar-container {
    padding: 0px;
  }
  #loginButton {
    background-color: transparent;
    border: 1px solid #c2c2c2;
    color: #1757ff;
    font-family: "DM Sans", sans-serif;
    border-radius: 0.8rem;
    width: 30%;
  }
  #loginButton:hover {
    transition: 0.5s ease-in-out;
    background-color: #1757ff;
    color: #fff;
    border: none;
  }
}
