#modal-body {
  padding: 0px;
  position: relative;
}
#carosel-col {
  padding: 0px;
}
#carousel-image-container {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}
#carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#design-details-col {
  padding-left: 3rem;
  padding-right: 3rem;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
#rating-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
#rating-numbers {
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  margin-left: 10px;
}
#design-name {
  font-family: "DM Sans", sans-serif;
  font-size: 25px;
  margin-top: 60px;
  font-weight: 500;
}
#design-description-text {
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  margin-top: 30px;
  font-weight: 400;
}
#button-div {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
#line-div {
  width: 100%;
  height: 1.5px;
  background-color: #d1d4d8;
}
#more-design-label {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 5px;
}
#more-design-image {
  width: 100%;
  height: 155px;
  object-fit: cover;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  border-radius: 1.2rem;
}
#close-button {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 30px;
}

@media only screen and (max-width: 600px) {
  #carousel-image-container {
    height: 200px; /* Adjust the height for smaller screens */
  }
  #modal-body::-webkit-scrollbar,
  #design-details-col::-webkit-scrollbar {
    display: none;
  }
  #close-button {
    cursor: pointer;
    position: absolute;
    top: 220px;
    right: 20px;
  }
}
