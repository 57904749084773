#paymenthistorymodal1{
    color:grey;
    font-size: 1.1rem;
    font-weight: 600;
}
.ddvZdT {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.1rem;
}
#paymenthistorymodal2{
    font-size: 1.5rem;
}