/* body::-webkit-scrollbar {
  overflow-x: hidden !important;
} */
#errorText {
  color: red;
  font-family: "DM Sans", sans-serif;
  font-size: 0.7rem;
  margin-top: 0.2rem;
}

.errorText {
  color: red;
  font-family: "DM Sans", sans-serif;
  font-size: 0.7rem;
  margin-top: 0.2rem;
}
body {
  font-family: "DM Sans", sans-serif;
}
.cont-visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
