#chat-container {
  padding-right: 10px;
  margin-top: 1rem;
}
#chat-header-container {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: #2053db;
  padding: 20px 25px;
}
#chat-body-container {
  margin-top: 1rem;
  border: 1px solid gray;
  height: 75vh;
  border-radius: 0.3rem;
  padding: 20px 20px;
  overflow: scroll;
  padding-bottom: 80px;
}
#outside-container {
  position: relative;
}
#name-desgination-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-family: "DM Sans", sans-serif;
}
#chatdetail-profile-img {
  width: 50px;
  border-radius: 100px;
  height: 50px;
  object-fit: cover;
  margin-right: 1rem;
}
#chatdetail-person-name {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: white;
}
#chatdetail-person-desgination {
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: white;
}
#dot-icon-div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#message-enter-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  width: 98%;
  background-color: white;
}

#type-message-container {
  background-color: #edeff7;
  padding: 10px 15px;
  border-radius: 10rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 92%;
  align-self: center;
  padding-right: 30px;
}

#type-message-input {
  background-color: #edeff7;
  border-radius: 10rem;
  width: 80%;
  border: none;
  box-shadow: none;
  font-family: "DM Sans", sans-serif;
}
#type-message-input:active {
  box-shadow: none;
}
#send-btn {
  cursor: pointer;
  cursor: pointer;
  width: 53px;
}
.sent-message {
  background-color: #0141ab;
  padding: 0.5rem;
  margin: 4px;
  color: white;
  border-top-left-radius: 1.3rem;
  border-bottom-left-radius: 1.3rem;
  border-top-right-radius: 1.3rem;
  font-size: 0.8rem;
  display: flex;
  align-self: flex-end;
  min-width: 4rem;
  max-width: 80%;
  text-align: right;
}

.received-message {
  background-color: #0141ab;
  padding: 8px;
  margin: 4px;
  border-top-right-radius: 1.3rem;
  border-bottom-left-radius: 1.3rem;
  border-bottom-right-radius: 1.3rem;
  color: white;
  font-size: 0.8rem;
  display: flex;
  align-self: flex-start;
  min-width: 4rem;
  max-width: 80%;
}
#attach-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}
#mobile-chat-container {
  display: none;
}
#mobile-back-icon {
  cursor: pointer;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  #chat-container {
    padding-left: 10px;
  }
  #chat-header-container {
    padding: 20px 5px;
  }
  #type-message-container {
    width: 80%;
  }
}
