#comments1 {
  background-color: #eef2f9;
  padding: 1rem;
  border-radius: 1.5rem;
  border: var(--Radius-Sharp, 1px) solid rgba(0, 0, 0, 0.05);
  margin-top: 1.5rem;
}
#comments2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
#comments3 {
  font-size: 1.1rem;
  font-weight: 500;
}
#comments4 {
  font-size: 1rem;
  font-weight: 400;
  color: #676767;
}
#comments5 {
  margin-top: 1rem;
  color: #3E3E3E;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.profile-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 0.3rem;
}
.initials-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  text-transform: uppercase;
}
