#sidebar1 {
  height: 100vh;
  overflow-y: scroll;
  border-right: 1px solid #dbdbdb;
  position: fixed;
  width: 16.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem;
  background-color: #fff;
}

#sidebar1::-webkit-scrollbar {
  display: none;
}

#sidebar2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#sidebar2Logo {
  width: 8rem;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

#sidebar4 {
  background-color: #e2f3f6;
  padding: 1.2rem 0.8rem;
  border-radius: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#sidebar4Image {
  width: 80%;
}

#sidebar5 {
  margin-top: 1rem;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  color: #2e343e;
}

#sidebar6 {
  font-family: "DM Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  color: #2e343e;
}

#sidebar7 {
  background-color: #1551ed;
  padding: 0.6rem;
  border-radius: 40px;
  margin-top: 0.5rem;
  box-shadow: 0px 4px 4px 0px #091e3e1f;
  font-family: "DM Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

/* media queries started from here */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
  #sidebar1 {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #sidebar1 {
    /* width: 25%;
    overflow-x: hidden;
    padding: 1rem; */
    display: none;
  }
  #sidebar2Logo {
    width: 6rem;
    margin-top: 0rem;
    margin-bottom: 1.5rem;
  }
  #sidebar4 {
    padding: 0.8rem 0.5rem;
  }
  #sidebar4Image {
    width: 50%;
  }
  #sidebar5 {
    margin-top: 0.6rem;
    font-size: 0.9rem;
  }

  #sidebar6 {
    font-size: 0.7rem;
  }

  #sidebar7 {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #sidebar1 {
    width: 25%;
    overflow-x: hidden;
    padding: 1rem;
  }
  #sidebar2Logo {
    width: 7rem;
    margin-top: 0.5rem;
  }
  #sidebar4 {
    padding: 0.8rem 0.5rem;
  }
  #sidebar4Image {
    width: 55%;
  }
  #sidebar5 {
    margin-top: 0.6rem;
    font-size: 0.9rem;
  }

  #sidebar6 {
    font-size: 0.7rem;
  }

  #sidebar7 {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1449px) {
  #sidebar1 {
    width: 17%;
    overflow-x: hidden;
    padding: 1rem;
  }
  #sidebar2Logo {
    width: 7rem;
    margin-top: 0.6rem;
  }
  #sidebar4 {
    padding: 0.8rem 0.5rem;
  }
  #sidebar4Image {
    width: 60%;
  }
  #sidebar5 {
    margin-top: 0.6rem;
    font-size: 0.9rem;
  }

  #sidebar6 {
    font-size: 0.7rem;
  }

  #sidebar7 {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1450px) and (max-width: 1699px) {
  #sidebar1 {
    width: 17%;
    overflow-x: hidden;
  }
  #sidebar2Logo {
    width: 7rem;
    margin-top: 0.8rem;
  }
  #sidebar4 {
    padding: 0.8rem;
  }
  #sidebar4Image {
    width: 70%;
  }
  #sidebar5 {
    margin-top: 0.6rem;
    font-size: 0.9rem;
  }

  #sidebar6 {
    font-size: 0.7rem;
  }

  #sidebar7 {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1700px) {
  #sidebar1 {
    display: none;
  }
} */
