#discuss1{
    display: flex;
    justify-content: center;
    align-items: center;
}
#discuss2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    font-family: "DM Sans", sans-serif;
}
#discuss3{
    text-align: center;
    margin-top: 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
}
#discuss4{
    background-color: #07b13e;
    border: none;
    color: white;
    border-radius: 1.3rem;
    padding:0.6rem;
}