#myAccountMainContainer {
    border-bottom: 1px solid #dbdbdb;
  }
  
  #myAccount1 {
    font-family: "DM Sans", sans-serif;
    font-size: large;
    font-weight: 500;
    text-align: left;
    color: #313438;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  #myAccount1Icon {
    cursor: pointer;
    margin-right: 10px;
  }
  
  #myAccount2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "DM Sans", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: left;
    color: #313438;
    transition: all 0.15s;
  }
  
  #myAccount2Text {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #myAccount2Active {
    display: flex;
    align-self: center;
    cursor: pointer;
    transition: all 0.15s;
    width: 3rem;
    height: 8px;
    background-color: #1757ff;
    border-radius: 5px 5px 0px 0px;
    margin-top: 0.5rem;
  }
  
  #myAccount2Inactive {
    display: flex;
    align-self: center;
    cursor: pointer;
    transition: all 0.15s;
    width: 5rem;
    height: 8px;
    background-color: #fff;
    border-radius: 5px 5px 0px 0px;
    margin-top: 0.5rem;
  }
  
  #myAccount3 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  #myAccount3Button {
    background-color: #1551ed;
    padding: 0.7rem;
    border-radius: 40px;
    margin-top: -0.6rem;
    box-shadow: 0px 4px 4px 0px #091e3e1f;
    font-family: "DM Sans", sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }
  
  #myAccount3ButtonCreate {
    margin-right: 0.5rem;
    width: 25px;
    height: 25px;
  }
  
  #project-detail-backicon {
    cursor: pointer;
    margin-right: 0.2rem;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 992px) {
    #myAccount2 {
      padding-top: 1rem;
      justify-content: space-around;
      gap: 1.5rem;
      width: 100%;
      font-size: 0.8rem;
    }
    #myAccount3Button {
      font-size: 0.7rem;
      padding: 0.4rem;
      margin-top: 0rem;
    }
  }
  