#description-container {
  border: 1px solid #bdc8dc;
  border-radius: 1rem;
  padding: 20px 17px;
  margin-top: 1.6rem;
}
#description-label {
  color: #474d56;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
}
#description-text {
  color: #788396;
  font-family: "DM Sans", sans-serif;
  text-align: justify;
  font-size: 15px;
  margin-top: 0.7rem;
}
#material-card-container {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  min-height: 60px;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
}
#material-label {
  color: #474d56;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
#material-parent-container {
  border: 1px solid #bdc8dc;
  border-radius: 1rem;
  padding: 10px 17px;
  margin-top: 1rem;
}

#project-payment-card-container {
  border: 1px solid #bdc8dc;
  border-radius: 1rem;
  padding: 15px;
  margin-top: 1rem;
}

#payment-card-div {
  background-color: #ebedf5;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 10px;
}
#payment-card-label {
  font-weight: 600;
  font-size: 13px;
  font-family: "DM Sans", sans-serif;
  color: gray;
}
#payment-card-text {
  font-weight: 600;
  font-size: 14.5px;
  font-family: "DM Sans", sans-serif;
  color: black;
  margin-top: 0.3rem;
}
#payment-card-pay-emi {
  background-color: #ebedf5;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px 20px;
  width: 100%;
}

#material-div {
  background-color: #f2f4f9;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px 25px;
  width: 200px;
  margin-bottom: 10px;
}
#material-product-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
}
#material-label-text {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}
#material-img {
  width: 100%;
  height: 150px;
  margin-bottom: 0.5rem;
}

#projectdetails-card-container {
  position: relative;
  /* margin-top: 1.6rem; */
}
#projectdetails-card-image {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  width: 100%;
  height: 215px;
  object-fit: cover;
}

#projectdetails-card-open-title-container {
  padding: 10px 15px;
  border: 1.2px solid #bdc8dc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
#projectdetails-card-open-title {
  color: #788396;
  font-family: "DM Sans", sans-serif;
}

#projectdetails-card-open-body-container {
  padding: 10px 15px;
  border-top-width: 0px;
  border: 1.2px solid #bdc8dc;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  cursor: pointer;
  border-top-width: 0px;
}
#projectdetails-card-number-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 0.6rem;
  margin-bottom: 1.5rem;
  font-family: "DM Sans", sans-serif;
}
#projectdetails-card-number-text {
  width: 90%;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
}

#project-payment-div {
  border-top: 1px solid #bdc8dc;
  border-bottom: 1px solid #bdc8dc;
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#project-payment-text {
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  color: #788396;
}

#project-estimate-div {
  background-color: #f1fdff;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
}
#project-estimate-text {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  color: #788396;
  margin-left: 10px;
}
#project-blue-bar {
  background-color: #1757ff;
  height: 25px;
  width: 7px;
  border-radius: 14px;
}

#projectdetails1 {
  font-size: 0.9rem;
  color: #5a5b5c;
  font-family: "DM Sans", sans-serif;
  background-color: #ebf0f4;
  border: 1px solid #d7d8da;
  box-shadow: none;
  padding: 0.5rem;
}

#projectdetails2 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 280px;
  font-family: "DM Sans", sans-serif;
  font-size: medium;
  font-weight: 600;
}
