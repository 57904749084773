#payment-card-container {
  position: relative;
  margin-top: 1.6rem;
  border-radius: 1rem;
  border: 1.2px solid #c3ccda;
  padding: 10px;
}
#payment-product-image {
  border-radius: 0.5rem;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#product-image-div {
  object-fit: cover;
  width: 150px;
  height: 100px;
  margin-right: 20px;
}
#product-image-name-loc-col {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
#product-name-txt {
  font-weight: 600;
  font-size: 15px;
  font-family: "DM Sans", sans-serif;
}
#product-location-txt {
  font-weight: 600;
  font-size: 13px;
  font-family: "DM Sans", sans-serif;
  color: gray;
}
#location-icon {
  margin-right: 0.3rem;
  height: 15px;
  width: 12px;
}
#payment-price-div {
  background-color: #ebedf5;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding-left: 15px;
}
#payment-price-label {
  font-weight: 500;
  font-size: 13px;
  font-family: "DM Sans", sans-serif;
  color: gray;
}
#payment-price-text {
  font-weight: 600;
  font-size: 14.5px;
  font-family: "DM Sans", sans-serif;
  color: black;
  margin-top: 0.3rem;
}
#download-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 9px 10px;
  background-color: #d9d9d9;
  border-radius: 2rem;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  transition: background-color all 1s;
  user-select: none;
}
#download-icon {
  margin-right: 0.3rem;
  cursor: pointer;
}
#download-button:hover {
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 1s;
  cursor: pointer;
  color: white;
}
#download-btn-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
#project-id-div {
  background-color: #eff8fa;
  border-radius: 1rem;
  padding: 3px 5px;
  width: 80%;
  display: flex;
  color: gray;
  justify-content: center;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  margin-top: 0.3rem;
}

@media only screen and (max-width: 767px) {
  #payment-price-col {
    margin-top: 1rem;
    height: 80px;
  }
  #download-btn-col {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
#paymentcard1 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: blue;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}
#paymentcard2 {
  margin-right: 0.5rem;
  margin-bottom: 0.3rem;
}
#paymentcard3 {
  margin-top: 1rem;
}
