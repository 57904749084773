#myAccountSidebar1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 0.8rem;
  padding: 0.5rem 1rem;
  transition: all 0.4s;
}

#myAccountSidebar1.active {
    background-color: #F4F7FC;
    border-radius: 8px;
}

#myAccountSidebar3 {
  margin-left: 10px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #313438;
}

#myAccountSidebar3.active {
    color: #1551ED;
  }
