/* Header.css */

#location-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "DM Sans", sans-serif;
}

#location-label {
  color: #788396;
  font-weight: 600;
  font-size: 13px;
  font-family: "DM Sans", sans-serif;
}

#location-address-text {
  color: #2e343e;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
}

#line-div {
  transform: rotate(180deg);
  transform-origin: center;
  background-color: #d1d1d1;
  height: 22px;
  width: 2px;
  margin-left: 11px;
  margin-right: 11px;
}

#drop-down-menu-icon {
  cursor: pointer;
}

#location-icon {
  width: 25px;
  height: 35px;
}

#logo {
  width: 8rem;
}

#mobileViewHeaderAfterLogin {
  display: none;
}

#chooseTabContainer {
  background-color: #f1f1f1;
  border-radius: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 7px;
  font-family: "DM Sans", sans-serif;
  margin-top: 5px;
}

#activeTabContainer {
  transition: all 0.3s;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 50%;
  border-radius: 3rem;
  color: black;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
}
#inactiveTabContainer {
  transition: all 0.3s;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 50%;
  border-radius: 3rem;
  color: #414f4c;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
}

#wishList-cart-profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 7px;
  font-family: "DM Sans", sans-serif;
}
#wishlist-icon {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}
#cart-container {
  background-color: #f1f1f1;
  border-radius: 3rem;
  padding: 5px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 3rem;
  margin-left: 1rem;
  font-family: "DM Sans", sans-serif;
}
#cart-text {
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
}
#profile-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 3rem;
  margin-left: 1rem;
}
#profile-name-text {
  font-weight: 500;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
}
#profile-text-div {
  height: 35px;
  width: 35px;
  border-radius: 2rem;
  padding: 1rem;
  background-color: #1551ed;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "DM Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
}
#profile-img {
  height: 45px;
  width: 45px;
  border-radius: 2rem;
  cursor: pointer;
}
#no-profile {
  height: 45px;
  width: 45px;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1551ed;
}

@media only screen and (max-width: 1049px) {
  #header1 {
    display: none;
  }
  #mobileViewHeaderAfterLogin {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    /* margin-right: 0.5rem; */
  }
  #logo {
    width: 7rem;
    height: 100%;
  }
  #chooseTabContainer {
    width: 180px;
  }
  #chooseTabContainer {
    padding: 5px;
  }

  #activeTabContainer {
    height: 30px;
    font-size: 0.8rem;
  }
  #inactiveTabContainer {
    height: 30px;
    font-size: 0.8rem;
  }
  #wishlist-icon {
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    margin-top: 1rem;
  }
  #cart-container {
    background-color: #f1f1f1;
    border-radius: 3rem;
    padding: 5px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 3rem;
    margin-left: 0px;
    margin-top: 1.4rem;
    font-family: "DM Sans", sans-serif;
  }
  #mobile-wishlist-container {
    background-color: #f1f1f1;
    border-radius: 3rem;
    padding: 5px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 3rem;
    margin-top: 1.4rem;
    font-family: "DM Sans", sans-serif;
  }
  #wishlist-cart-icon {
    width: 17px;
    height: 17px;
  }
  #profile-name-text {
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
    margin-top: 0.6rem;
  }
  #profile-container {
    margin-left: 0px;
    width: 120px;
    margin-top: 0.5rem;
  }
  #mobile-profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  #mobile-wish-cart-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  #location-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "DM Sans", sans-serif;
    position: absolute;
    bottom: 20px;
  }
  #border-line-div {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 2px solid gainsboro;
  }
  .location-icon {
    width: 30px;
    height: 30px;
  }
}
