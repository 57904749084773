#no-projects-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  font-family: "DM Sans", sans-serif;
}
#noprojectfound1{
  margin-top: 1.3rem;
}
