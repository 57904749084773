#calendly1 {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 6rem;
  max-width: 100%;
  /* min-height: 70vh; */
  border: 1px solid rgb(211, 211, 211);
  background-color: white;
}

#calendly2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.slots-container {
  padding-left: 20px;
  margin-top: 20px;
}

#calendy3 {
  margin-top: -0.8rem;
  /* font-size: 1rem;
  font-weight: 500; */
  /* margin-top: 0.5rem; */
  font-size: 1.2rem;
  font-weight: 600;
}

#calendly4 {
  margin-top: 1rem;
  border: 1px solid blue;
  width: 100%;
}

#calendly5 {
  font-size: 1rem;
  color: #706f6f;
  font-weight: 500;
  margin-top: 0.7rem;
}

#calendy6 {
  font-size: 1.2rem;
  color: black;
  font-weight: 700;
  margin-top: 0.6rem;
}

#calendly7 {
  margin-top: 0.5rem;
  color: #706f6f;
  font-weight: 500;
}

#calendly8 {
  margin-top: 0.1rem;
}

#calendly9 {
  margin-top: 0.8rem;
  color: #706f6f;
  font-weight: 400;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
  width: 80px;
}

.rdp-selected .rdp-day_button {
  background: blue;
  color: white;
}

.calendly-column {
  position: relative;
  padding-right: 20px;
}

.calendly-column:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #ddd;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  #calendly1 {
    padding: 3rem;
    margin-top: 2rem;
  }

  #calendly2 {
    margin-top: 3rem;
  }

  .calendly-column {
    padding-right: 10px;
  }

  .calendly-column:not(:last-child)::after {
    display: none;
  }

  .slots-container {
    padding-left: 10px;
  }

  #calendy3 {
    font-size: 0.9rem;
  }

  #calendly5 {
    font-size: 0.9rem;
  }

  #calendy6 {
    font-size: 1.1rem;
  }

  #calendly8 {
    margin-top: 0.05rem;
  }

  #calendly9 {
    margin-top: 0.4rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  #calendly1 {
    padding: 1.5rem;
    margin-top: 1rem;
  }

  .slots-container {
    padding-left: 5px;
  }

  #calendy3 {
    font-size: 0.8rem;
    margin-top: 1rem;
  }

  #calendly5 {
    font-size: 0.8rem;
  }

  #calendy6 {
    font-size: 1rem;
  }

  #calendly7 {
    margin-top: 0.2rem;
  }

  #calendly8 {
    font-size: 0.8rem;
  }

  #calendly9 {
    margin-top: 0.3rem;
    font-size: 0.8rem;
  }

  .calendly-column {
    padding-right: 5px;
  }
  .rdp-month_caption {
    display: flex;
    align-content: center;
    height: var(--rdp-nav-height);
    font: var(--rdp-month_caption-font);
    padding: 14px;
}
.rdp-nav {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  display: flex;
  align-items: center;
  height: var(--rdp-nav-height);
  padding-right: 14px;
}


}
#calendly10{
  margin-top: 12rem;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
#calendly11{
  /* margin-top: 4rem;*/
}
#calendly12{
  padding-left: 1rem;
}
#calendly13{
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}
#calendly14{
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
}
#calendly15{
  color: blue;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 0.7rem;
}
#calendly16{
  height: 12rem;
  width:17rem
}
#calendly17{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
#calendly18{
  /* background-image: linear-gradient(to right,#7b78c4,#ffffff); */
  background-image: linear-gradient(to right,#f7f7f9,#c3cafd);
}
