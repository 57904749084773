#wishlist1 {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
}
#wishlist2 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
#wishlist3 {
  margin-top: 0.6rem;
}
#wishlist4 {
  color: #2e343e;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
#wishlist5 {
  background-color: #f7f8fa;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1rem;
}
#wishlist6 {
  margin-top: 1.2rem;
}
#wishlist7 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0.4rem;
  border-radius: 100%;
  cursor: pointer;
}
#wishlist8 {
  font-size: 1.2rem;
}
#wishlist9 {
  height: 15vh;
  width: 100%;
  margin-top: 0.3rem;
  border-radius: 0.8rem;
}
#wishlist10 {
  color: #2e343e;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
}
#wishlist11 {
  font-size: 0.9rem;
  color: #747f9c;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
#wishlist12 {
  margin-top: 0.5rem;
}
#wishlist13 {
  background-color: #1b53e4;
  color: white;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  border-radius: 0.4rem;
  padding: 0.33rem;
  width: 75%;
  font-size: 0.8rem;
}
#wishlist14 {
  margin-left: 0.5rem;
  font-size: 1rem;
  margin-top: -0.2rem;
}
#wishlist15 {
  color: #2e343e;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  margin-top: 2.1rem;
}
