#faq1 {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
}
#faq2 {
  margin-top: 2rem;
}
#faq3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  min-height: 60vh;
}

.accordion-item {
  border: none;
}

.accordion-header {
  border: none;
}

.accordion-body {
  border: none;
}
.accordion-item:first-of-type .accordion-button {
  box-shadow: none;
  background-color: white;
}
.accordion-button:focus {
  box-shadow: none;
  background-color: white;
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: white;
}
#faq4 {
  color: #0a79fb;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
}
#faq5 {
  font-family: "DM Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  color: #494747;
}
