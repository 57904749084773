#ordersummery1 {
  border-radius: 1rem;
  border: 1px solid #cdd8ff;
  background: linear-gradient(
    163deg,
    #ecf5fb 1.19%,
    #f9ece2 28.63%,
    #f7f2f8 48.05%,
    #e4f5ff 71.13%,
    #eaf2fd 100%
  );
  /* min-height: 80vh; */
  padding: 0.8rem;
}
#ordersummery2 {
  background-color: #f7f8fa;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 0.7rem;
  border: 2px solid #fff;
}
#ordersummery3 {
  height: 12vh;
  width: 100%;
  margin-top: 0.2rem;
}
#ordersummery4 {
  color: #2e343e;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
}
#ordersummety5 {
  font-size: 0.9rem;
  color: #747f9c;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
#ordersummety6 {
  font-size: 0.8rem;
  color: #747f9c;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
#ordersummety7 {
  color: #2e343e;
  font-size: 1rem;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
}
#ordersummery8 {
  color: #2e343e;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
}
#ordersummery9 {
  border-radius: 1rem;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.5);
  padding: 0.6rem;
  margin-top: 0.7rem;
}
#ordersummery10 {
  color: #2e343e;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
}
#ordersummery11 {
  color: #2e343e;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
}
#ordersummery12 {
  margin-top: 0.4rem;
}
#ordersummery13 {
  display: flex;
  justify-content: flex-end;
  color: #2e343e;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}
#ordersummery14 {
  color: #747f9c;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}
#ordersummery15 {
  border-radius: 0.7rem;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.5);
  padding: 0.6rem;
  margin-top: 0.7rem;
  cursor: pointer;
}
#ordersummery16 {
  color: #2e343e;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 0.2rem;
}
#couponDropdown {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ddd;
  color: #747f9c;
  margin-top: 0.7rem;
  font-weight: 500;
  padding: 10px;
  font-family: "DM Sans", sans-serif;
  border-radius: 1rem;
}

#couponDropdown ul {
  list-style-type: none;
  padding: 0;
}

#couponDropdown li {
  padding: 5px 0;
  cursor: pointer;
}

#couponDropdown li:hover {
  background-color: #eee;
}
#ordersummery17 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem;
}
#ordersummery18 {
  font-size: 1.3rem;
}
