#standard1 {
  border-radius: var(--Radius-s, 8px);
  background: #f7f8fa;
  padding: 1rem;
  /* margin-top: 1.3rem; */
  cursor: pointer;
  height: 270px;
}
#standard2 {
  color: #2e343e;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
}
#standard3 {
  color: #747f9c;
  font-family: "DM Sans", sans-serif;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.2rem;
}
#standard4 {
  margin-top: 1.5rem;
  width: 100%;
  height: 10rem;
  border-radius: 0.7rem;
}
#standard5 {
  display: flex;
  justify-content: center;
  align-items: center;
}
#heartIcon {
  background-color: #fff;
  border-radius: 50%;
  color: rgb(117, 115, 115);
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  animation: 0.5s linear infinite;
}
#wishlisted-heart-icon {
  cursor: pointer;
  color: red;
  transition: 0.5s all ease-in-out;
  animation: 0.5s linear infinite;
}
