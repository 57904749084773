#AllChat-Container {
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 1rem;
  margin-top: 1rem;
  height: 90vh;
}
#Search-Container {
  background-color: #f6f6f6;
  border-radius: 5rem;
  padding: 3px 18px;
  width: 100%;
  border: 0px;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}
#search-input {
  border: 0px;
  background-color: #f6f6f6;
  margin-left: 5px;
  box-shadow: none;
}
#search-input:active {
  box-shadow: none;
}
#header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-top: 1rem;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 10px;
}

#header-allchats-text {
  font-weight: 600;
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
}
#profile-img-msg-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
#person-name {
  font-size: 16px;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
}
#person-msg {
  font-size: 13px;
  margin-top: 3px;
  font-family: "DM Sans", sans-serif;
}
#person-name-msg-div {
  width: 63%;
}
#person-profile-img {
  width: 50px;
  border-radius: 100px;
  height: 50px;
  object-fit: cover;
}
#time-text-div {
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#user-row {
  cursor: pointer;
}
#unread-message-div {
  font-size: 12px;
  color: gray;
  background-color: #f6f6f6;
  padding: 4px 10px;
  border-radius: 13px;
}
#Mobile-AllChat-Container {
  display: none;
}
