#myprofile1 {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

#myprofile2 {
  width: 100%;
}

#myprofile3 {
  position: absolute;
  bottom: -60px;
  z-index: 1;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  border: 4px solid white;
  margin-left: 2.5rem;
}

#myprofile5Main {
  border: 1px solid #c3ccda;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

#myprofile6 {
  width: 250px;
  height: 250px;
  border-radius: 100%;
}

#myprofile7 {
  border: 1px solid #1b53e4;
  background-color: #fff;
  color: #1b53e4;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 26px;
  margin-top: 1rem;
  padding: 0.8rem 1rem;
}
#myprofile8 {
  margin-top: 1rem;
}
#myprofile9 {
  color: var(--Secondary-Secondary800, #637d92);
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}
#myprofile10 {
  border-radius: var(--Radius-s, 8px);
  background: #f2f5f9;
  box-shadow: none;
  border: none;
  padding: 0.6rem;
  color: #323f49;
  font-family: "DM Sans", sans-serif;
  font-family: 400;
}
#myprofile11 {
  margin-left: 10.5rem;
  margin-top: -4.5rem;
  color: #1a3b6b;
  font-size: 1.2rem;
  cursor: pointer;
}
#myprofile12 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
#myprofile13 {
  color: #1449f2;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  border: 1px solid #9fbeec;
}
#myprofile14 {
  position: absolute;
  bottom: -60px;
  z-index: 1;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  border: 4px solid white;
  margin-left: 2.5rem;
  background-color: #1449f2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.7rem;
}
#btn-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
}

@media only screen and (max-width: 768px) {
  #myprofile11 {
    margin-left: 7rem;
    margin-top: -4.5rem;
    cursor: pointer;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  #myprofile3 {
    position: absolute;
    bottom: -50px;
    z-index: 1;
    width: 100px;
    height: 100px;
    border-radius: 100%;
  }
  #myprofile2 {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 768px) {
  #myprofile3 {
    position: absolute;
    top: 45px;
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 100%;
  }
  #myprofile2 {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
  }
}
