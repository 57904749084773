#paymentdetails1 {
  border-radius: 1rem;
  background: #f6f6f6;
  padding: 1.5rem;
}
#paymentdetails2 {
  color: #2e343e;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
}
#paymentdetails3 {
  color: #747f9c;
  font-family: "DM Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.3rem;
}
#paymentdetails4 {
  margin-top: 1.3rem;
}
#paymentdetails5 {
  background-color: white;
  padding: 0.9rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
}
#paymentdetails6 {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
#paymentdetails7 {
  margin-top: 0.8rem;
  display: flex;
  justify-content: flex-end;
}
#paymentdetails8 {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  color: #43bb89;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
}
#paymentdetails9 {
  height: 7rem;
  width: 100%;
}
#paymentdetails10 {
  margin-top: 1.5rem;
}
#paymentdetails11 {
  border-radius: 0.6rem;
  background: #1b53e4;
  width: 100%;
  padding: 0.8rem;
  text-align: center;
  color: white;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}
#paymentdetails12 {
  border-radius: 1rem;
  background: #f6f6f6;
  padding: 1.5rem;
  margin-top: 1rem;
}
#paymentdetails13 {
  color: #1751e8;
  font-family: "DM Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
}
#paymentdetails14 {
  color: #788396;
  font-family: "DM Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.6rem;
}
#paymentdetails15 {
  margin-top: 0.9rem;
}
#paymentdetails16 {
  padding: 0rem;
  text-decoration: underline;
  color: #1751e8;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
}
#paymentdetails17 {
  display: flex;
  justify-content: center;
  align-items: center;
}
#paymentdetails18 {
  height: 10.4rem;
}
