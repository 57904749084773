.tab-slide-enter {
  opacity: 0;
  transform: translateY(20px);
}

.tab-slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.tab-slide-exit {
  opacity: 1;
  transform: translateY(0);
}

.tab-slide-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms, transform 300ms;
}

.active-tab {
  font-weight: bold;
}
/* Legaltabs.css */

.legal-tabs-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.legal-tabs {
  grid-column: 1 / -1;
  border-bottom: 1px solid #e7edf4;
}

.legal-tab-content {
  grid-column: 1 / -1;
}
.css-o82f6a-MuiButtonBase-root-MuiTab-root {
  color: #3a3a3a !important;
  font-size: small !important 
}
.css-rwf70c-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1757ff !important;
  font-size: small 
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #1757ff !important;
}

@media (max-width: 600px) {
  .legal-tabs-container {
    grid-template-columns: 1fr;
  }
}
.legal-dropdown {
  width: 100%;
}

#materialtabs5 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  font-family: "DM Sans", sans-serif;
  font-size: larger;
  font-weight: 600;
}

