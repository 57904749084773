/* Updated responsive styles */
.container-main {
  display: flex;
  justify-content: center;
  padding: 10px;
}

#container {
  padding: 20px;
  box-shadow: 0 2px 8px rgba(48, 47, 47, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 600px; /* Limits the max width for larger screens */
  display: flex;
  justify-content: center;
  flex-direction: column; /* Adjusts to column for better stacking on small devices */
}

.first-box,
.second-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.second-box-div {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid gray;
  width: 100%;
  background-image: linear-gradient(to right,#f7f7f9,#c3cafd);
}

#open-button {
  border-radius: 20px;
  padding: 5px 20px;
  border: 1px solid gray;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  cursor: pointer;
}

.tick-span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-top: -1rem;
}

.first-row {
  display: flex;
  align-items: center;
  font-size: 16px;
}

#scheduled-p {
  font-size: 25px;
  font-weight: 600;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  #container {
    padding: 15px;
    width: 90%;
  }

  .first-box, .second-box {
    margin-bottom: 15px;
  }

  #scheduled-p {
    font-size: 22px;
  }

}

@media (max-width: 480px) {
  #container {
    padding: 10px;
  }

  #open-button {
    padding: 5px 15px;
  }

  #scheduled-p {
    font-size: 20px;
  }
  #calendly42{
    margin-top: 5rem;
  }
}

#calendly41{
  margin-top: 0.1rem;
}
#calendly42{
  margin-top: 3rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 0.5rem;
  /* margin-top: 2rem; */
}


@media (max-width: 768px) {
  #calendly42{
    margin-top: 3rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
    /* margin-top: 2rem; */
  }
}
